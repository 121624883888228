<template>
  <div class="video">

    <Navigation v-bind:showbackurl="true" v-bind:backurl="backurl"></Navigation>

    <player></player>

    <Footer></Footer>

  </div>
</template>

<script>
// @ is an alias to /src
import Player from '@/components/Player.vue'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Video',
  components: {
    Player,
    Navigation,
    Footer
  },

  data() {
    return {
      backurl: '/',
    };
  },

  created() {
      this.backurl = this.$route.meta.backurl;
  },

  beforeDestroy() {
    this.$store.commit('page_transition')
  },

}
</script>
