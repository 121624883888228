<template>
  <div class="player" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
    <div>

      <div class="infos">

        <h1 class="title video_title" v-bind:class="{ show: duration <= 3 }">
          {{ videoname }}
        </h1>

        <button class="link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && duration == 0) }">Video Starten</button>

      </div>

      <vue-plyr-custom ref="plyr" :options="{autoplay: false, autopause: false, fullscreen: {enabled: false},controls: [ 'play', 'progress', 'current-time', 'mute', 'volume' ]}">
        <div data-plyr-provider="vimeo" :data-plyr-embed-id="videoid"></div>
      </vue-plyr-custom>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Player',

  props: {
    title: String,
  },

  data() {
    return {
      duration: null,
      status: '',
      videoid: '',
      videoname: '',
      backurl: '/',
      backname: 'Home',
    };
  },

  created() {
      this.videoid = this.$route.meta.videoid;
      this.videoname = this.$route.meta.videoname;
      this.backurl = this.$route.meta.backurl;
      this.backname = this.$route.meta.backname;
  },

  mounted() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }
  },

  beforeDestroy() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
        this.$refs.plyr.player.destroy()
      }
    }
  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      this.$refs.plyr.player.embed.play()
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'
      this.$router.push({ name: this.backname })
    },
    videoTimeUpdated: function() {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }
    }
  }
}
</script>